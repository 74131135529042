<template>
  <div>
      <van-popup v-model="addresShow" position="bottom" :style="{ height: '30%' }" :close-on-click-overlay=false lazy-render @click-overlay="overPop">
          <div class="title">选择检测中心</div>
         <van-radio-group v-model="radio" >
               <div class="" v-for="(item,index) in Addres" :key="item.id" :class="{bg:index == radio,text:true}">
                <van-radio :name="index" checked-color="#fcc870" icon-size="18px" @click="radioClose(item)">
                  <h4>{{item.company}} <span>{{item.phone}}</span></h4>
                  <p>{{item.preAddr + item.address}}</p>
              </van-radio>

               </div>
          </van-radio-group>
      </van-popup>
  </div>
</template>

<script>
export default {
    // addresShow: 弹出隐藏或显示   Addres:取货中心地址   defaultAddress:默认地址
    props:['addresShow','Addres','defaultAddress'],
    data() {
        return {
            radio:'2'
        }
    },
    methods:{
        radioClose(item){
            this.$emit('update:addresShow',false)
            this.$emit('update:defaultAddress',item)
            
        },
        // 点击遮罩层触发
        overPop(){
            this.$emit('update:addresShow',false)
        }
    },
}
</script>

<style lang="scss" scoped>
.title{
    width: 100%;
    font-size: 30px;
    padding: 10px 0;
    text-align: center;
    color: #999999;
}
.text{
    width: 100%;
    padding: 20px 14px;
    
    h4{
        padding: 10px 6px;
    }
    p{
        padding: 10px 6px;
        color: #999999;
    }
}
.bg{
    background-color: #FFF9EB;

}
</style>