var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pop" },
    [
      _c(
        "van-popup",
        {
          attrs: { "close-on-click-overlay": false, round: "" },
          model: {
            value: _vm.popShow,
            callback: function($$v) {
              _vm.popShow = $$v
            },
            expression: "popShow"
          }
        },
        [
          _c(
            "div",
            { staticClass: "popup" },
            [
              _c(
                "van-radio-group",
                {
                  model: {
                    value: _vm.radio,
                    callback: function($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio"
                  }
                },
                [
                  _c(
                    "van-radio",
                    {
                      attrs: {
                        name: "1",
                        "checked-color": "#fcc870",
                        "icon-size": "22px"
                      }
                    },
                    [
                      _c("h1", [_vm._v("到店自取")]),
                      _c(
                        "div",
                        { staticClass: "quhuo" },
                        [
                          _c("div", [_vm._v("(请选择取货中心)")]),
                          _c("van-icon", {
                            attrs: { name: "arrow" },
                            on: { click: _vm.addresClk }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "diZhi" },
                    [
                      _c("van-icon", { attrs: { name: "location-o" } }),
                      _c("div", { staticClass: "address" }, [
                        _c("p", [_vm._v(_vm._s(_vm.defaultAddress.company))]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.defaultAddress.preAddr +
                                _vm.defaultAddress.address
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "van-radio",
                    {
                      attrs: {
                        name: "2",
                        "checked-color": "#fcc870",
                        "icon-size": "22px"
                      }
                    },
                    [
                      _c("h1", [_vm._v("快递到家")]),
                      _c(
                        "div",
                        { staticClass: "quhuo" },
                        [
                          _c("div", [_vm._v("(请选择收货地址)")]),
                          _c("van-icon", {
                            attrs: { name: "arrow" },
                            on: { click: _vm.kdAddresClk }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "diZhi kuaiDi" },
                    [
                      _c("van-icon", { attrs: { name: "location-o" } }),
                      _c("div", { staticClass: "address" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.uesrAddress
                                ? _vm.uesrAddress.preAddr +
                                    _vm.uesrAddress.address
                                : ""
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("van-field", {
                    attrs: {
                      label: "备注",
                      placeholder: "请尽快准备发货",
                      "input-align": "right",
                      border: false,
                      size: "24px"
                    },
                    model: {
                      value: _vm.beiZhuVal,
                      callback: function($$v) {
                        _vm.beiZhuVal = $$v
                      },
                      expression: "beiZhuVal"
                    }
                  }),
                  _c("footer", { staticClass: "foot" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cancel footBtn",
                        on: { click: _vm.cancel }
                      },
                      [_vm._v(" 取消 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "confirm footBtn",
                        on: { click: _vm.submit }
                      },
                      [_vm._v(" 确认 ")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("addresPop", {
        attrs: {
          addresShow: _vm.addresShow,
          Addres: _vm.Addres,
          defaultAddress: _vm.defaultAddress
        },
        on: {
          "update:addresShow": function($event) {
            _vm.addresShow = $event
          },
          "update:addres-show": function($event) {
            _vm.addresShow = $event
          },
          "update:Addres": function($event) {
            _vm.Addres = $event
          },
          "update:addres": function($event) {
            _vm.Addres = $event
          },
          "update:defaultAddress": function($event) {
            _vm.defaultAddress = $event
          },
          "update:default-address": function($event) {
            _vm.defaultAddress = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }