var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          style: { height: "30%" },
          attrs: {
            position: "bottom",
            "close-on-click-overlay": false,
            "lazy-render": ""
          },
          on: { "click-overlay": _vm.overPop },
          model: {
            value: _vm.addresShow,
            callback: function($$v) {
              _vm.addresShow = $$v
            },
            expression: "addresShow"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("选择检测中心")]),
          _c(
            "van-radio-group",
            {
              model: {
                value: _vm.radio,
                callback: function($$v) {
                  _vm.radio = $$v
                },
                expression: "radio"
              }
            },
            _vm._l(_vm.Addres, function(item, index) {
              return _c(
                "div",
                { key: item.id, class: { bg: index == _vm.radio, text: true } },
                [
                  _c(
                    "van-radio",
                    {
                      attrs: {
                        name: index,
                        "checked-color": "#fcc870",
                        "icon-size": "18px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.radioClose(item)
                        }
                      }
                    },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(item.company) + " "),
                        _c("span", [_vm._v(_vm._s(item.phone))])
                      ]),
                      _c("p", [_vm._v(_vm._s(item.preAddr + item.address))])
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }