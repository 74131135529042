<template>
  <div class="purchaseOrder">
        <van-nav-bar
       title="买料订单"
       left-arrow
       fixed
       safe-area-inset-top
       placeholder 
       @click-left="leftClick"
     />
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
            <!-- //  买料结算单 -->
            
            <div class="list" v-for="(item) in buyStatement" :key="item.id">
                <div class="title">
                    <h2>黄金金条9999</h2>
                    <div class="paymentInfo">
                        {{item.payStatus == 'WAIT'?'待付款':已付款}}
                    </div>
                </div>
                <div class="orderInfo">
                    <p>总克重: <i>{{item.weight.toFixed(2)}}克</i> </p>
                    <p>总金额: <i>{{item.money}}元</i> </p>
                    <p>订单时间: <i>{{item.created | formatDate_}}</i> </p>
                </div>
                <footer class="orderHao">
                    <p>订单号:  {{item.openNo}}</p>
                    <div class="xiangqing" @click="toBuyDetails(item.id)">详情<van-icon name="arrow" /></div>
                </footer>
            </div>

            <!-- 买料订单 -->
            <div class="list" v-for="(item) in buyOrder" :key="item.oid">
                <input type="checkbox" class="checkBox" name="checkBoxTest" :value="item"  v-model="checkList" @change="checkBox">

                <div class="title">      
                    <h2 style="paddingLeft:20px">{{item.goods[0].name}}</h2>
                    <div class="paymentInfo">
                        {{item.status == "WAIT_FETCH"?'待取料':'待确定'}}
                    </div>
                </div>
                <div class="orderInfo">
                    <p>总克重: <i>{{item.weight.toFixed(2)}}克</i> </p>
                    <p>总金额: <i>{{item.goods[0].price}}元</i> </p>
                    <p>订单时间: <i>{{item.createTime | formatDate_}}</i> </p>
                </div>
                <footer class="orderHao">
                    <p>订单号:  {{item.oid}}</p>
                    <div class="xiangqing" @click="TobuyOrderParticulars(item)">详情<van-icon name="arrow" /></div>
                </footer>
            </div>

           </van-list>
        </van-pull-refresh>
        <footer class="quliao">
            <div class="weight">
                重量: <i>{{weightAll}}g</i>
            </div>
            <div class="money">
                金额: <i>{{moneyAll.toFixed(2)}}元</i>
            </div>
            <div class="reclaiming" @click="reclaiming">
                取料
            </div>
        </footer>

        <!-- 弹出层 -->
            <keep-alive>
                 <popup ref="popup" :popShow.sync="popShow" :orderList="orderList"></popup>
            </keep-alive>
  </div>
</template>

<script>
import popup from "./components/buyOrderPop.vue";
import * as api from'@/utils/api'
export default {
     components:{
           popup
     },
     data() {
         return {
              list: [],
              loading: false,
              finished: false,
              refreshing: false,
              //  买料结算单
              buyStatement:[],
              //   买料订单
              buyOrder:[],
              //  复选框选中的数据  
              checkList:[],
              // weightAll 重量
              weightAll:0,
              // moneyAll 金额
              moneyAll:0,
              //  控制弹出层隐藏与显示
              popShow:false,
              //  复选框选中的定价单Id数组
              orderList:[]
        }
     },
     methods: {
        //  待处理详情按钮事件
         TobuyOrderParticulars(item){
            this.$router.push({
                name:'buyOrderParticulars',
                query:{order:item.oid}
            })
             console.log(item);
         },
        //  详情按钮点击事件
         toBuyDetails(item){
            this.$router.push({
                name:'purchaseSettlement',
                query:{openId:item}
            })
             console.log(item);
         },
        // 顶部左侧图标点击事件
         leftClick(){
            this.$router.push({
                name:'person'
            })
         },
        //  取料按钮的点击事件
         reclaiming(){
            //  已经选择提料的订单
             if (this.weightAll) {
                //  清空之前的数据,以本次复选框选中的数据为准
                 this.orderList = []
                //  变量复选框数据
                 this.checkList.forEach(item =>{
                // 添加待orderList 中
                 this.orderList.push(item.oid)
                 })
                //  打开弹出层
                 this.popShow = true
                //  一定是在dom更新完毕之后调用
                this.$nextTick(()=>{
               //  调用子组件获取取货地址方法
                 this.$refs.popup.addres()
                })
 
             }else{
                //  未选择
                this.$toast('请选择需要提料的订单')
             }
         },
        //  复选框的监听事件
         checkBox(){
            //  声明变量用来存储重量和金额
                let weight = 0
                let money = 0
                this.checkList.forEach(item =>{
                    // 遍历复选框选中的数据 算法添加到变量中
                    weight += item.weight
                    money += item.money
                    // 订单单号
                    this.orderList.push(item.oid)
                })
                this.weightAll = weight
                this.moneyAll = money
         },

        async onLoad() { 
            // 发请求获取买料订单数据
            let res = await api.getBuyDeposit()
            // 待付款
            this.buyStatement = res.statement
            // 待取料
            this.buyOrder = res.order
            this.loading = false
            this.finished = true
         },
        async  onRefresh() {
            let res = await api.getBuyDeposit()
            this.buyStatement = res.statement
            this.buyOrder = res.order
           // 重新加载数据
            this.loading = true
           // 将 loading 设置为 true，表示处于加载状态
            this.refreshing = false;
         },
    },
        async  activated(){
            if (!this.$route.query.address) {
            this.weightAll = 0
            this.moneyAll = 0
            this.checkList =[]
            }

            let res = await api.getBuyDeposit()
            // 待付款
            this.buyStatement = res.statement
            // 待取料
            this.buyOrder = res.order
    }
       
}
</script>

<style lang='scss' scoped> 
.purchaseOrder{
    background-color: #f5f5f5;
    .list{
        width: 620px;
        height: 300px;
        background-color:#ffffff;
        border-radius: 14px;
        box-shadow:0px 0px 4px 1px rgba(0, 0, 0, 0.2);
        margin: 20px auto;
        padding: 20px 15px;
        position: relative;

        .title{
            width: 100%;
            padding: 4px 0;
            display: flex;
            justify-content: space-between; 
            align-items: center;
            h2{
                font-size: 30px;
            }
            .paymentInfo{
                width: 100px;
                height: 44px;
                text-align: center;
                line-height: 42px;
                border: 1px solid #e1c683;
                color: #e1c683;
                border-radius: 14px;
            }

        }
        .orderInfo{
            padding-bottom: 15px;
            border-bottom: 1px solid #e7e7e7;

        }
        .orderHao{
            margin-top: 25px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            .van-icon {
                top: 3px;
            }
        }
    }
    .quliao{
        height: 100px;
        background-color:#333224 ;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        color: #ffffff;
        line-height: 100px;
        padding-left: 30px;
        font-size: 30px;
        .weight{
            flex:2;
            text-align: left;
        }
        i{
            color: #fff;
        }
        .money{
            flex:2;
            text-align: left;
        }
        .reclaiming{
            flex:1;
            background-color: #f9b324;
            text-align: center;
            font-size: 36px;
            color: #2e2e2e;
        }
    }
}

::v-deep{
    .borderbox{
        border: none;
    }
    .van-pull-refresh{
        padding-bottom: 100px;
    }
     .van-nav-bar{
        height: 100px;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
        .van-nav-bar__title{
            font-size: 40px;
        }
        .van-icon{
            font-size: 40px;
            color: #1e1e1e;
        }
        }
     }
}
i{ 
    font-style:normal;
    color:#2e2e2e;
    margin-left: 6px;
    
}
p{
    color: #dadada;
    padding: 3px 0;
    font-size: 24px;
    i{
        font-size: 22px;
    }
}
input[type="checkbox"]{
    width:4px;
    height:4px;
    display: inline-block;
    text-align: center;
    vertical-align: middle; 
    line-height: 30px;
    border-radius: 50%;
    position: relative;
    }
input[type="checkbox"]::before{
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    background: #fff;
    width:40px;
    height: 40px;
    border: 1px solid #ffc538;
    border-radius: 50%;}

input[type="checkbox"]:checked::before{
    content: "\2713";
    background-color: #fff;
    position: absolute;
    top: -6px;
    left: -6px;
    width:40px;
    height: 40px;
    border: 1px solid #ffc538;
    border-radius: 50%;
    color:#ffc538;
    line-height: 40px;
    font-size: 28px;
    font-weight: bold;
    }

</style>