var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "purchaseOrder" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "买料订单",
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
          placeholder: ""
        },
        on: { "click-left": _vm.leftClick }
      }),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            [
              _vm._l(_vm.buyStatement, function(item) {
                return _c("div", { key: item.id, staticClass: "list" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("h2", [_vm._v("黄金金条9999")]),
                    _c("div", { staticClass: "paymentInfo" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.payStatus == "WAIT" ? "待付款" : _vm.已付款
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "orderInfo" }, [
                    _c("p", [
                      _vm._v("总克重: "),
                      _c("i", [_vm._v(_vm._s(item.weight.toFixed(2)) + "克")])
                    ]),
                    _c("p", [
                      _vm._v("总金额: "),
                      _c("i", [_vm._v(_vm._s(item.money) + "元")])
                    ]),
                    _c("p", [
                      _vm._v("订单时间: "),
                      _c("i", [
                        _vm._v(_vm._s(_vm._f("formatDate_")(item.created)))
                      ])
                    ])
                  ]),
                  _c("footer", { staticClass: "orderHao" }, [
                    _c("p", [_vm._v("订单号: " + _vm._s(item.openNo))]),
                    _c(
                      "div",
                      {
                        staticClass: "xiangqing",
                        on: {
                          click: function($event) {
                            return _vm.toBuyDetails(item.id)
                          }
                        }
                      },
                      [
                        _vm._v("详情"),
                        _c("van-icon", { attrs: { name: "arrow" } })
                      ],
                      1
                    )
                  ])
                ])
              }),
              _vm._l(_vm.buyOrder, function(item) {
                return _c("div", { key: item.oid, staticClass: "list" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkList,
                        expression: "checkList"
                      }
                    ],
                    staticClass: "checkBox",
                    attrs: { type: "checkbox", name: "checkBoxTest" },
                    domProps: {
                      value: item,
                      checked: Array.isArray(_vm.checkList)
                        ? _vm._i(_vm.checkList, item) > -1
                        : _vm.checkList
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.checkList,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = item,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.checkList = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checkList = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checkList = $$c
                          }
                        },
                        _vm.checkBox
                      ]
                    }
                  }),
                  _c("div", { staticClass: "title" }, [
                    _c("h2", { staticStyle: { paddingLeft: "20px" } }, [
                      _vm._v(_vm._s(item.goods[0].name))
                    ]),
                    _c("div", { staticClass: "paymentInfo" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.status == "WAIT_FETCH" ? "待取料" : "待确定"
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "orderInfo" }, [
                    _c("p", [
                      _vm._v("总克重: "),
                      _c("i", [_vm._v(_vm._s(item.weight.toFixed(2)) + "克")])
                    ]),
                    _c("p", [
                      _vm._v("总金额: "),
                      _c("i", [_vm._v(_vm._s(item.goods[0].price) + "元")])
                    ]),
                    _c("p", [
                      _vm._v("订单时间: "),
                      _c("i", [
                        _vm._v(_vm._s(_vm._f("formatDate_")(item.createTime)))
                      ])
                    ])
                  ]),
                  _c("footer", { staticClass: "orderHao" }, [
                    _c("p", [_vm._v("订单号: " + _vm._s(item.oid))]),
                    _c(
                      "div",
                      {
                        staticClass: "xiangqing",
                        on: {
                          click: function($event) {
                            return _vm.TobuyOrderParticulars(item)
                          }
                        }
                      },
                      [
                        _vm._v("详情"),
                        _c("van-icon", { attrs: { name: "arrow" } })
                      ],
                      1
                    )
                  ])
                ])
              })
            ],
            2
          )
        ],
        1
      ),
      _c("footer", { staticClass: "quliao" }, [
        _c("div", { staticClass: "weight" }, [
          _vm._v(" 重量: "),
          _c("i", [_vm._v(_vm._s(_vm.weightAll) + "g")])
        ]),
        _c("div", { staticClass: "money" }, [
          _vm._v(" 金额: "),
          _c("i", [_vm._v(_vm._s(_vm.moneyAll.toFixed(2)) + "元")])
        ]),
        _c(
          "div",
          { staticClass: "reclaiming", on: { click: _vm.reclaiming } },
          [_vm._v(" 取料 ")]
        )
      ]),
      _c(
        "keep-alive",
        [
          _c("popup", {
            ref: "popup",
            attrs: { popShow: _vm.popShow, orderList: _vm.orderList },
            on: {
              "update:popShow": function($event) {
                _vm.popShow = $event
              },
              "update:pop-show": function($event) {
                _vm.popShow = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }