<template>
    <div class="pop">
      <van-popup v-model="popShow" :close-on-click-overlay=false round >
        <div class="popup">
            
          <van-radio-group v-model="radio">
               <!-- 到店自取 -->
              <van-radio name="1" checked-color="#fcc870" icon-size="22px">
                  <h1>到店自取</h1>
                 <div class="quhuo">
                     <div>(请选择取货中心)</div>
                     <van-icon name="arrow" @click="addresClk"/>
                 </div>
              </van-radio>
              <!-- 取货地址 -->
              <div class="diZhi">
                <van-icon name="location-o" />
                <div class="address">
                    <p>{{defaultAddress.company}}</p>
                    <p>{{defaultAddress.preAddr + defaultAddress.address}}</p>
                </div>
                
              </div>
              <!-- 快递到家 -->
              <van-radio name="2" checked-color="#fcc870" icon-size="22px">
                   <h1>快递到家</h1>
                 <div class="quhuo">
                     <div>(请选择收货地址)</div>
                     <van-icon name="arrow"  @click="kdAddresClk"/>
                 </div>
              </van-radio>
              <!-- 收货地址 -->
              <div class="diZhi kuaiDi">
                <van-icon name="location-o" />
                <div class="address">
                    <p>{{uesrAddress?uesrAddress.preAddr + uesrAddress.address:''}}</p>
                </div>
                

              </div>
              <!-- 备注 -->
              <van-field
                v-model="beiZhuVal"
                label="备注"
                placeholder="请尽快准备发货"
                input-align="right"
                :border="false"
                size="24px"
              />
              <footer class="foot">
                  <div class="cancel footBtn" @click="cancel">
                      取消
                  </div>
                  <div class="confirm footBtn" @click="submit">
                      确认
                  </div>
              </footer>
          </van-radio-group>
                
            <!-- 取货地址 -->
          
       
        </div>
      </van-popup>

    <addresPop :addresShow.sync="addresShow" :Addres.sync="Addres" :defaultAddress.sync="defaultAddress"></addresPop>

    </div>
</template>

<script>
import * as api from'@/utils/api'
import addresPop from'./addresPop.vue'
export default {
    props:['popShow','orderList'],
    components:{
        addresPop,
    },
    data() {
        return {
            radio: '1',
            beiZhuVal: '',
            // 控制地址弹出层的隐藏显示
            addresShow:false,
            expresShow:false,
            // 取货中心地址
            Addres:[],
            // 默认地址
            defaultAddress:{},
            // 用户自己的默认地址
            uesrAddress:{},
        }
    },
    methods:{
        // 提交按钮点击事件
       async submit(){
                  let res
                //    判断是到店自取或快递到家
            if (this.radio == '1') {
                // 到店自取
                // 发请求获取到店自取数据信息
                 res = await api.getOrderReclaiming({
                // 取货类型,EXPRESS-快递, PLACE-到店取货
                type:'PLACE',
                orderList:this.orderList,
                // 备注
                remark:this.beiZhuVal?this.beiZhuVal:'请尽快准备货',
                // 快递用户地址Id, PLACE时必须
                systemAddressId:this.defaultAddress.id
            })
                // 保存到vuex 
            }else{
                // 快递到家
                // 发请求获取快递到家数据信息
                 res = await api.getOrderReclaiming({
                // 取货类型,EXPRESS-快递, PLACE-到店取货
                type:'EXPRESS',
                orderList:this.orderList,
                 // 备注
                remark:this.beiZhuVal?this.beiZhuVal:'请尽快准备货',
                // 快递用户地址Id, EXPRESS时必须
                addressId:this.uesrAddress.id
            })
                // 保存到vuex
            }

                this.$store.dispatch('getAsyncOrderInfo',res)
                localStorage.setItem('buyOrderInfo',JSON.stringify(res))
                // 关闭弹出层
                this.$emit('update:popShow' ,false)
                // 跳转路由
                this.$router.push({
                     name:'carryApply',
            })
        },
        // 快递到家 右侧图标事件
        kdAddresClk(){
            console.log('11');
            this.$router.push({
                name:'address',
                query:{kdAddress:true}
            })

        },
        // 到店自取 右侧图标事件
        addresClk(){
            this.addresShow = true
        },
        // 取消按钮事件
        cancel(){
           this.$emit('update:popShow' ,false)
        },
        // 封装获取取货地址方法
        async addres(){
            // 发请求获取数据
            let res = await api.querySysAddressList()
            // 筛选数据
            this.Addres = res.data.filter(item => item.enable)
            this.defaultAddress = this.Addres[0]
            // 发请求获取用户快递地址
            let getAddress = await api.getAddress()
            let uesrAddres =  getAddress.filter(item =>item.defAddr)
            // console.log('1',uesrAddres);
            this.uesrAddress = uesrAddres[0]
            console.log(this.uesrAddress);
        }
    },
    deactivated(){
        this.uesrAddress = {}
    },
    activated(){
        this.uesrAddress = JSON.parse(localStorage.getItem('address'))
    }
}
</script>

<style lang="scss" scoped>
.popup{
    width: 89vw;
    padding: 20px 40px;

      .van-radio__label{
          width: 100%;
          display: flex;
          line-height: 30px;

            h1{
                font-size: 30px;
            }
            .quhuo{
                flex: 1;
                display: flex;
                justify-content: space-between;
                .van-icon{
                    font-size:36px;
                    top: -3px;
                }
            }
    }
      .diZhi{
            padding: 25px 10px 20px 50px;
            width: 100%;
            display: flex;
            border-bottom: 1px solid #c1c1c1;
            margin-bottom: 18px;
            .van-icon{
                font-size: 30px;
                top: 30px;
            }
            .address{
                p{
                    font-size: 24px;
                }
            }
    }
      .kuaiDi{
            .van-icon{
                font-size: 30px;
                top: 4px;
            }
    }
       .foot{
        padding: 30px 50px 10px;
        margin-top: 8px;
        border-top: 1px solid #c1c1c1;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .footBtn{
            width: 200px;
            text-align: center;
            line-height: 50px;
            border-radius: 50px;
        }
        .cancel{
            border: 1px solid #f9b324;
            color: #f9b324;
        }
        .confirm{
            background-color: #f9b324;
            color: #fff;
        }
    }

}

    
</style>